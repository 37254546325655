import _ from 'lodash';
import { forTranslation } from './forTanslation';

// import { languages } from './config';
const { languages } = require('./config');

console.log(forTranslation);

const newArray = [];
for (const key in forTranslation) {

  const lanCode = forTranslation[key].CODE;
  // console.log(lanCode);
  // console.log(key, temp[key]);
  newArray.push(
    { 
      lCode: lanCode,
      data: { translation: forTranslation[key] }
    }
  )
}
console.log(newArray);

// console.log(_.keyBy(temp, 'CODE'));

// export const resources = 
//   _.chain(newArray)
//     .keyBy('lCode')
//     .mapValues('data')
//     .value();

// console.log(resources);


const lan2 = languages;

export const resources = Object.assign(
	{},
	...Object.keys(languages).map((index) => {
		return {
			[languages[index]]: {
				// translations: require('../locales/' + languages[index] + '/translation.json'),
				translation: require('./locales/' + languages[index] + '/common.json'),
        // translations: require('./locales/' + 'af' + '/common.json'),
			},
		};
	}),
);

console.log(resources);