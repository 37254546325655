import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next/hooks';

import i18n from '../../../i18n';
import Button from '../../Button/Button';
import SmallModal from '../../Modal/SmallModal';
import TransBackdrop from '../../Backdrop/TransBackdrop';
import LanguageList from '../../../translation/LanguageList';
import { resources } from '../../../translation/translation';

import './LanguageSelect.css';

const LanguageSelect = (props) => {

  const selectedLanguage = resources[i18n.language].translation.LANGUAGE;

  const [t] = useTranslation('translation');

  const [showLanguageModal, setShowLanguageModal] = useState(false);

  const showLanguageModalHandler = () => {
    setShowLanguageModal(!showLanguageModal);
  };

  const languageModalBody = (
    <div>
      <TransBackdrop onClick={showLanguageModalHandler} />
      <SmallModal style="languageModal">
        <div className="languageSelect__closeModalButton"
          onClick={showLanguageModalHandler}
        >
          X
      </div>
        <LanguageList
          showLanguageModalHandler={showLanguageModalHandler}
        // getLanguageNameHandler={getLanguageNameHandler}
        // i18n={props.i18n}
        />
      </SmallModal>
    </div>
  );

  return (
    <div className="languageSelect__container">
      <div className="languageSelect__content">
        <span>
          {/* Language: */}
          {t('language.text1')}:
        </span>
        <span className="languageSelect__content">{selectedLanguage}</span>

        <div className="">
          <Button
            mode="flat" type="submit"
            // disabled={descriptionInput ? descriptionInput.length > 300 : null}
            onClick={showLanguageModalHandler}
          >
            {/* (change) */}
            ({t('language.text2')})
          </Button>
        </div>

        {showLanguageModal ? languageModalBody : null}
      </div>
    </div>
  );
}

export default LanguageSelect;
