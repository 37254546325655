// const React = window.React = require('react');
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next/hooks';
import speakeasy from "speakeasy"; //Generate a secret key First.
import QRCode from 'qrcode';

import Button from '../Button/Button';

import { nodeUrl, userAccountUrl } from '../../App';

import classes from './TwoFactorAuth.module.css';

const TwoFactorAuth = (props) => {
  const [t] = useTranslation('translation');

  const [twoFactorQRcode, setTwoFactorQRcode] = useState('');
  const [tfaB64buffer, setTfaB64buffer] = useState('');
  const [user2FAInput, setUser2FAInput] = useState('');
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [about2FAA, setAbout2FAA] = useState('');
  const [resultOf2FA, setResultOf2FA] = useState('');
  const [is2FASetup, setIs2FASetup] = useState(false);
  const [doneStep1, setDoneStep1] = useState(false);
  const [step2Message, setStep2Message] = useState('');
  const [pressStart, setPressStart] = useState(false);
  
  const [isDisableClicked, setIsDisableClicked] = useState(false);

  const ls2FAState = localStorage.getItem('set2fa');

  useEffect(() => {
    console.log('ls2FAstate', ls2FAState);
    if (ls2FAState && props.tfaRequired) {
      setIs2FAEnabled(true);
    }
  },[ls2FAState, props.tfaRequired]);

  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged(function (user) {
  //     if (user) {
  //       // User is signed in.
  //       return firebase.database().ref('users/' + user.uid).once('value')
  //         .then(function (snapshot) {
  //           const userData = snapshot.val();
  //           // console.log(userData);

  //           if (userData.set2fa) {
  //             setIs2FAEnabled(true);
  //           }

  //         });

  //     } else {
  //       // No user is signed in.
  //       console.log('not fb user in')
  //     }
  //   })

  // }, []);


  const user2FAInputHandler = (event, inputType) => {
    setUser2FAInput(event.target.value);

    if (event.target.value.length === 6 && inputType === 'test') {
      user2FATestSubmitHandler(event.target.value);
    }

    if (event.target.value.length === 6 && inputType === 'submit') {
      user2FASubmitHandler(event.target.value);
    }
  }

  const about2FAAHandler = () => {
    setAbout2FAA(<div>
      <div>
        Two-factor Authentication (2FA) is a security method based on one-time passcode authentication. To enable Two factor Authentication, you need Authenticator such as Google Authenticator in your device.
        {t('2FA.text4')}
        </div>
      <button className="" onClick={pressStartHandler} >
        Start Two-Factor Authentication Setup
        {t('2FA.text5')}
        </button>
    </div>);
  }

  const pressStartHandler = () => {
    setPressStart(!pressStart);
  }

  const user2FATestSubmitHandler = async (userInput) => {
    try {

      const result = await fetch(nodeUrl + '/two-factor-auth/tfa-key', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        // body: JSON.stringify({
        //   tfaBuffer: tfaBuffer,
        // })
      });
  
      const resData = await result.json();
  
      console.log(resData, result);


      const twoFactorkey = Buffer.from(resData.twoFactorKey).toString('base64');
      console.log(twoFactorkey);
  
      const token = speakeasy.totp({
        secret: Buffer.from(twoFactorkey),
        encoding: 'base32'
      })
      // console.log(token);
  
      var tokenValidates = speakeasy.totp.verify({
        secret: Buffer.from(twoFactorkey),
        encoding: 'base32',
        token: userInput,
        window: 2
      });
  
      console.log('tokenValidates', tokenValidates);
      // return;

      if (tokenValidates) {
        //// set2fa true
        const result2 = await fetch(nodeUrl + '/two-factor-auth/set-success-tfa', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
          // body: JSON.stringify({
          //   tfaBuffer: tfaBuffer,
          // })
        });
    
        const resData2 = await result2.json();
    
        console.log(resData2, result2);
        
        // console.log('user write success in db');
        setResultOf2FA(<div style={{fontWeight:"bold"}}>
          Number Matched!, One-time passcode will need when Login.
          {t('2FA.text20')}
          </div>);
        setIs2FASetup(true);


        setTimeout(() => {
          moveToServiceHandler();
        }, 1000*5);

  
      } else {
        setResultOf2FA(<div>
          {/* Not Number Matched!, Check number again, or Start from Step 1 again. */}
          {t('2FA.text19')}
          </div>);
      }
  

    } catch(err) {
      console.log(err);
    }





    // firebase.auth().onAuthStateChanged(function (user) {
    //   if (user) {
    //     // User is signed in.
    //     return firebase.database().ref('users/' + user.uid).once('value')
    //       .then(function (snapshot) {
    //         const userData = snapshot.val();
    //         // console.log(userData);

    //         const twoFactorkey = Buffer.from(userData.twofactorkey.data).toString('base64');
    //         // console.log(twoFactorkey);

    //         const token = speakeasy.totp({
    //           secret: Buffer.from(twoFactorkey),
    //           encoding: 'base32'
    //         })
    //         console.log(token);

    //         var tokenValidates = speakeasy.totp.verify({
    //           secret: Buffer.from(twoFactorkey),
    //           encoding: 'base32',
    //           token: userInput,
    //           window: 2
    //         });

    //         console.log('tokenValidates', tokenValidates);
    //         if (tokenValidates) {

    //           firebase.database().ref('users/' + user.uid).update({
    //             set2fa: true,
    //             // pass2fa: true
    //           }, function (error) {
    //             if (error) {
    //               // The write failed...
    //               console.log('user write error in db');
    //             } else {
    //               // Data saved successfully!
    //               console.log('user write success in db');
    //               setResultOf2FA(<div style={{fontWeight:"bold"}}>
    //                 {/* Number Matched!, One-time passcode will need when Login. */}
    //                 {t('2FA.text20')}
    //                 </div>);
    //               setIs2FASetup(true);

    //             }
    //           });

    //         } else {
    //           setResultOf2FA(<div>
    //             {/* Not Number Matched!, Check number again, or Start from Step 1 again. */}
    //             {t('2FA.text19')}
    //             </div>);
    //         }
    //       });

    //   } else {
    //     // No user is signed in.
    //     console.log('not sign in');
    //   }
    // });

  };

  const user2FASubmitHandler = async (userInput) => {
    try {
      const result = await fetch(nodeUrl + '/two-factor-auth/tfa-key', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        // body: JSON.stringify({
        //   tfaBuffer: tfaBuffer,
        // })
      });
  
      const resData = await result.json();
  
      console.log(resData, result);

      const twoFactorkey = Buffer.from(resData.twoFactorKey).toString('base64');
      // console.log(twoFactorkey);

      const token = speakeasy.totp({
        secret: Buffer.from(twoFactorkey),
        encoding: 'base32'
      })
      // console.log(token);

      var tokenValidates = speakeasy.totp.verify({
        secret: Buffer.from(twoFactorkey),
        encoding: 'base32',
        token: userInput,
        window: 2
      });

      console.log('tokenValidates', tokenValidates);
      if (tokenValidates) {

        // //// move to service site, clear lsData,
        // const token = localStorage.getItem('token');
        // const tgtexp = localStorage.getItem('TGTexp');

        // localStorage.removeItem('token');
        // localStorage.removeItem('email');
        // localStorage.removeItem('set2fa');
        // localStorage.removeItem('TGTexp');

        // const currentUrl = new URL(window.location.href);
        // const queryParams = currentUrl.searchParams;
        // const fromUrl = queryParams.get('fromUrl');

        // if (fromUrl) {
        //   console.log(fromUrl)
        //   window.location.replace(
        //     fromUrl + `/?tokenForCasTGT=${token}&casTGT=${localStorage.getItem('casTGT')}&TGTexp=${tgtexp}`
        //   );
        // }

        moveToServiceHandler();

        setResultOf2FA(<div>
          {t('2FA.text18', 'Number Matched!')}
          </div>);

      } else {
        setResultOf2FA(<div>
          {t('2FA.text17', 'Not Number Matched!, Check number again')}
          </div>);
      }
    } catch(err) {
      console.log(err);

    }

    // firebase.auth().onAuthStateChanged(function (user) {
    //   if (user) {
    //     // User is signed in.
    //     return firebase.database().ref('users/' + user.uid).once('value')
    //       .then(function (snapshot) {
    //         const userData = snapshot.val();
    //         // console.log(userData);

    //         const twoFactorkey = Buffer.from(userData.twofactorkey.data).toString('base64');
    //         // console.log(twoFactorkey);

    //         const token = speakeasy.totp({
    //           secret: Buffer.from(twoFactorkey),
    //           encoding: 'base32'
    //         })
    //         console.log(token);

    //         var tokenValidates = speakeasy.totp.verify({
    //           secret: Buffer.from(twoFactorkey),
    //           encoding: 'base32',
    //           token: userInput,
    //           window: 2
    //         });

    //         console.log('tokenValidates', tokenValidates);
    //         if (tokenValidates) {
    //           setResultOf2FA(<div>
    //             {/* Number Matched! */}
    //             {t('2FA.text18')}
    //             </div>);

    //           firebase.database().ref('users/' + user.uid).update({
    //             pass2fa: true
    //           }, function(error) {
    //             if (error) {
    //               // The write failed...
    //               console.log('update failed pass2fa');
    //             } else {
    //               // Data saved successfully!
    //               console.log('update success pass2fa');
    //               props.fbUserStateHandler({
    //                 user: user,
    //                 have_xxac: userData.have_xxac,
    //                 state: 'signin',
    //                 pass2fa: true
    //               })
    //             };
    //         });

    //         } else {
    //           setResultOf2FA(<div>
    //             {/* Not Number Matched!, Check number again */}
    //             {t('2FA.text17')}
    //             </div>);
    //         }

    //       })
    //       .catch(err => {
    //           console.log(err)
    //       });

    //   } else {
    //     // No user is signed in.
    //     console.log('not sign in');
    //   }
    // });

  }

  const createTFAkeyHandler = async (email) => {
    try {
      setDoneStep1(false);
      setStep2Message('');
      
      console.log(email);
      const issuer = 'auth(spaceeight)';
      var secret = speakeasy.generateSecret({
        length: 20,
        name: email,
        issuer: issuer,
      });
      // console.log(secret);
      // console.log('secret.base32', secret.base32); //using speakeasy generate one time token. 
  
      const tfaBuffer = Buffer.from(secret.base32, 'base64');
      // console.log(tfaBuffer);
      setTfaB64buffer(tfaBuffer);
  
      const recover = tfaBuffer.toString('base64');
      // console.log(recover);
  
      const qrUrl = speakeasy.otpauthURL({
        secret: secret.base32,
        label: encodeURIComponent(email),
        issuer: issuer,
      });
  
      QRCode.toDataURL(qrUrl, function (err, image_data) {
        // console.log(image_data); // A data URI for the QR code image
        setTwoFactorQRcode(image_data);
      });
  
      const result = await fetch(nodeUrl + '/two-factor-auth/create-tfa-key', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({
          tfaBuffer: tfaBuffer,
        })
      });
  
      const resData = await result.json();
  
      console.log(resData, result);
      
      // firebase.auth().onAuthStateChanged(function (user) {
      //   if (user) {
      //     // User is signed in.
      //     var secret = speakeasy.generateSecret({
      //       length: 20,
      //       name: user.email,
      //       issuer: 'StellarSpace'
      //     });
      //     // console.log(secret);
      //     // console.log('secret.base32', secret.base32); //using speakeasy generate one time token. 
  
      //     const tfaBuffer = Buffer.from(secret.base32, 'base64');
      //     // console.log(tfaBuffer);
      //     setTfaB64buffer(tfaBuffer);
  
      //     const recover = tfaBuffer.toString('base64');
      //     // console.log(recover);
  
      //     const qrUrl = speakeasy.otpauthURL({
      //       secret: secret.base32,
      //       label: encodeURIComponent(user.email),
      //       issuer: 'StellarSpace'
      //     });
  
      //     QRCode.toDataURL(qrUrl, function (err, image_data) {
      //       // console.log(image_data); // A data URI for the QR code image
      //       setTwoFactorQRcode(image_data);
      //     });
  
      //     //// save tfakey in db
      //     firebase.database().ref('users/' + user.uid).update({
      //       twofactorkey: tfaBuffer,
      //       tfk_createdat: Date.now(),
      //     }, function (error) {
      //       if (error) {
      //         // The write failed...
      //         console.log('user write error in db');
      //       } else {
      //         // Data saved successfully!
      //         console.log('user write success in db');
      //         setDoneStep1(true);
      //       }
      //     });
  
      //   } else {
      //     // No user is signed in.
      //   }
      // });
  
    } catch(err) {
      console.log(err);
    }
    

  }

  const fbUpdateUser2FAHandler = (tfkey) => {
    // firebase.auth().onAuthStateChanged(function (user) {
    //   if (user) {
    //     // User is signed in.

    //     firebase.database().ref('users/' + user.uid).update({
    //       // username: user.displayName,
    //       // email: result.user.email,
    //       // uid: result.user.uid,
    //       twofactorkey: tfaB64buffer,
    //       tfk_createdat: Date.now(),
    //     }, function (error) {
    //       if (error) {
    //         // The write failed...
    //         console.log('user write error in db');
    //       } else {
    //         // Data saved successfully!
    //         console.log('user write success in db');
    //         setStep2Message(<div style={{fontWeight:"bold"}}>
    //           {/* Thank you, Please finish to Step 3 */}
    //           {t('2FA.text21')}
    //           </div>);
    //       }
    //     });
    //   } else {
    //     // No user is signed in.
    //     console.log('no fb user');
    //   }
    // });

  };

  // const fbGetUser2FADataHandler = () => {
  //   firebase.auth().onAuthStateChanged(function (user) {
  //     if (user) {
  //       // User is signed in.
  //       return firebase.database().ref('users/' + user.uid).once('value')
  //         .then(function (snapshot) {
  //           const userData = snapshot.val();
  //           console.log(userData);

  //           const twoFactorkey = Buffer.from(userData.twofactorkey.data).toString('base64');
  //           // console.log(twoFactorkey);

  //           const token = speakeasy.totp({
  //             secret: Buffer.from(twoFactorkey),
  //             encoding: 'base32',
  //             time: Date.now() / 1000,
  //           })
  //           console.log(token);
  //         });

  //     } else {
  //       // No user is signed in.
  //       console.log('not sign in');
  //     }
  //   });
  // }

  // const fbEnable2FAuthHandler = () => {
  //   firebase.auth().onAuthStateChanged(function (user) {
  //     if (user) {
  //       // User is signed in.
  //       // firebase.database().ref('users/' + user.uid).update({
  //       //   twofactorAuthEnable: true
  //       // }, function (error) {
  //       //   if (error) {
  //       //     console.log('error occured in 2fa enable');
  //       //   } else {
  //       //     console.log('user 2fa enable true');
  //       //     setIs2FAEnabled(true);
  //       //   }
  //       // });

  //     } else {
  //       // No user is signed in.
  //       console.log('no fb user');
  //     }
  //   });
  // }

  const fbDisable2FAuthHandler = async () => {
    try {
      const result = await fetch(nodeUrl + '/two-factor-auth/disable-tfa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        // body: JSON.stringify({
        //   tfaBuffer: tfaBuffer,
        // })
      });
  
      const resData = await result.json();
  
      console.log(resData, result);

    } catch(err) {
      console.log(err);
    }


    // firebase.auth().onAuthStateChanged(function (user) {
    //   if (user) {
    //     // User is signed in.
    //     firebase.database().ref('users/' + user.uid).update({
    //       set2fa: false
    //     }, function (error) {
    //       if (error) {
    //         console.log('error occured in 2fa disable');
    //       } else {
    //         console.log('user 2fa disable false');
    //         setIs2FAEnabled(false);
    //       }
    //     });

    //   } else {
    //     // No user is signed in.
    //     console.log('no fb user');
    //   }
    // });
  };

  const moveToServiceHandler = (userAccountUrl) => {
    //// move to service site, clear lsData,
    const token = localStorage.getItem('token');
    const tgtexp = localStorage.getItem('TGTexp');
    const set2fa = localStorage.getItem('set2fa') || '';

    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('set2fa');
    localStorage.removeItem('TGTexp');

    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.searchParams;
    const fromUrl = queryParams.get('fromUrl');

    if (fromUrl) {
      console.log(fromUrl)
      window.location.replace(
        fromUrl + `/?tokenForCasTGT=${token}&casTGT=${localStorage.getItem('casTGT')}&TGTexp=${tgtexp}&set2fa=${set2fa}`
      );
    }

    if (userAccountUrl) {
      window.location.replace(
        userAccountUrl + `/?tokenForCasTGT=${token}&casTGT=${localStorage.getItem('casTGT')}&TGTexp=${tgtexp}&set2fa=${set2fa}`
      );
    }
  };

  return (
    <div>
      <div style={{ fontSize: "" }}>

        {/* {!is2FAEnabled ?
          <div>
            <div>
              <div>
                Use Two Factor Authentication for security
                {t('2FA.text2')}
              <div onClick={about2FAAHandler} style={{ textDecoration: "underline" }}
                > 
                (About Two-Factor Authentication)
                {t('2FA.text3')}
                </div></div>
              {about2FAA}
            </div>

        {pressStart ? 
        
            <div>
              <div style={{fontWeight:"bolder"}}>
                Step 1.
                {t('2FA.text6')} 1.
                </div>
              <div style={{fontWeight:"bolder"}}>
                Generete QR code for Authenticator
                {t('2FA.text7')}
                </div>
              <button className="s-button" 
                onClick={() => { createTFAkeyHandler(localStorage.getItem('email')); }} 
                disabled={is2FASetup}
              >
                Generate
                {t('2FA.text8')}
                </button>
              <div><img src={twoFactorQRcode}></img></div>



              <div style={{fontWeight:"bolder"}}>
                Step 2.
                {t('2FA.text6')} 2.
                </div>
              <div style={{fontWeight:"bolder"}}>
                Scan generated QR code using Authenticator
                {t('2FA.text9')}
                </div>
              <div>
                Authenticator will show 6 digit one-time passcode for StellarSpace.
                {t('2FA.text10')}
                </div>
              <div>
                Press Scanned button after added in Authnticator
                {t('2FA.text11')}
                </div>
              <button className="s-button" onClick={fbUpdateUser2FAHandler} disabled={is2FASetup}>
                Scanned
                {t('2FA.text12')}
                </button>
              {doneStep1 ? step2Message : null}

              <div style={{fontWeight:"bolder"}}>
                Step 3.
                {t('2FA.text6')} 3.
                </div>
              <div style={{fontWeight:"bolder"}}>
                Test Two-Factor Authentication
                {t('2FA.text13')}
                </div>
              <div>
                Enter 6 digit code of Authenticator into input and  push enter
                {t('2FA.text14')}
                </div>
              <div>
                input
                {t('2FA.text15')}
                </div>
              <input onChange={(event) => {user2FAInputHandler(event, 'test'); }} value={user2FAInput}></input>
              <button className="s-button" onClick={() => { user2FATestSubmitHandler(user2FAInput); setStep2Message(''); }}>
                enter
                {t('2FA.text16')}
                </button>
              <div>{resultOf2FA}</div>

            </div>
        : null}


          </div>
          : null 
          } */}

          {is2FAEnabled && (
            <div className={classes.tfaBody}>
              <div style={{fontWeight:"bold"}}>
                {t('2FA.text1', 'Please enter 6 digit code in Authenticator')}
              </div>
              <div>
                <input className={classes.tfaInput} 
                  onChange={(event) => {user2FAInputHandler(event, 'submit'); }} 
                  value={user2FAInput}>
                </input>

                {/* <button className="s-button" onClick={() => { user2FASubmitHandler(user2FAInput); }}>
                  enter
                  {t('2FA.text16')}
                </button> */}
              </div>
              {/* <div style={{marginTop:"60px"}}>
                <a href="/#userinformation">
                  (Disable Two-Factor Authentication ?)
                  ({t('userInfo.text15')} {t('userInfo.text9')} ? )
                </a>
              </div> */}

              {resultOf2FA}
              
              <div className={classes.tfaDisableTitle}>
                ({t('2FA.text21', 'Disable Two-Factor Authentication')}? )
              </div>
              <div className={classes.tfaDisableButton}>
                <Button mode="flat" design=""
                  onClick={() => {
                    // fbDisable2FAuthHandler();
                    setIsDisableClicked(true);
                  }}
                >
                  {t('2FA.text21', 'Disable Two-Factor Authentication')}
                </Button>
              </div>

              {isDisableClicked && (
                <div className={classes.tfaDisableLink}
                  onClick={() => { moveToServiceHandler(userAccountUrl) }}>
                  {t('2FA.text25', 'Page for Disabling')}
                </div>
              )}
            </div>
          )}
          
      </div>
    </div>
  )
}

export default TwoFactorAuth;