import React from 'react';
import { useState, useEffect } from 'react';
import { getInitialProps } from 'react-i18next/hooks';

import { nodeUrl } from '../../../App';
 
const Recaptcha = (props) => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  // const [v2Element, setV2Element] = useState('');
  const [v2Value, setV2Value] = useState('');

  const SITE_KEY = "6Lfh31EaAAAAAPNb0LNZv7xOq97P-NwzdA9DHoj0";
  // const SITE_KEY_V2 = "6LfgpFgaAAAAABaidvxbCOX5JGVFdsTQQI1rOmt5";
 
  useEffect(() => {

    setLoading(true); 

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
   
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
   
      if (isScriptExist && callback) callback();
    }
   
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () { 
      console.log("Script loaded!");

      getRecaptchaScore();
    });

  }, []);


  useEffect(() => {
    props.setRecaptchaLoadingHandler(loading);
  },[loading]);


  const getRecaptchaScore = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'onload' }).then(token => {
        submitData(token);
      });
    });
  };

  const handleOnClick = e => {
    e.preventDefault();
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        submitData(token);
      });
    });
  }
   
  const submitData = token => {
    // call a backend API to verify reCAPTCHA response
    fetch(nodeUrl + '/verify', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "name": name,
        "email": email,
        "g-recaptcha-response": token
      })
    })
    .then(res => res.json())
    .then(res => {
      console.log(res);
      setLoading(false);
      setResponse(res);

      console.log(props);
      props.setRecaptchaV3ScoreHandler(Number(res.score));

      // if (Number(res.score) < 0.3) {
      //   setV2Element(<ReCAPTCHAV2
      //   sitekey="6LfgpFgaAAAAABaidvxbCOX5JGVFdsTQQI1rOmt5"
      //   onChange={onChangeV2}
      // />)
      // }

    })
    .catch(err => {
      console.log(err);
    });
  }
 
  const onChangeV2 = (value) => {
    console.log("Captcha value:", value);
    // setV2Value(value);
  }

  return (
    <div className="App">

      {/* <h3>reCAPTCHA v3 in React - <a href="https://www.cluemediator.com/" target="_blank" rel="noopener noreferrer">Clue Mediator</a></h3>
      <div className="box">
        <label>Name: </label>
        <input type="text" onChange={e => setName(e.target.value)} value={name} />
      </div>
      <div className="box">
        <label>Email: </label>
        <input type="text" onChange={e => setEmail(e.target.value)} value={email} />
      </div>
      <button onClick={handleOnClick} disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</button>
      <br /><br />
      {response && <label>Output:<br /><pre>{JSON.stringify(response, undefined, 2)}</pre></label>} */}

      {/* {v2Element}
      {v2Value} */}
      </div>
  );
}
 
export default Recaptcha;