import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next/hooks';
import { Link } from 'react-router-dom';
import ReCAPTCHAV2 from "react-google-recaptcha";

// import Auth from './Auth';
import Input from '../../Form/Input/Input';
import i18n from '../../../i18n';
import Loader from '../../Loader/Loader';
import Button from '../../Button/Button';
// import Recaptcha from './Recaptcha';
// import ErrorHandler from '../ErrorHandler/ErrorHandler';
import { apiGetTest, signInWithGoogle, signInWithFacebook } from '../../util/user'
import { validEmail, isValidFromUrl } from '../../util/validators';
import { casUrl, nodeUrl } from '../../../App';

import logoImage from '../../../image/s8-logo.png';
import './Auth.css';

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";

// const url = 'http://cas.example.org:8500';


// const casUrl = process.env.REACT_APP_CASURL;
// const nodeUrl = process.env.REACT_APP_NODEURL;
// console.log(casUrl, nodeUrl);


const Signup = props => {
  console.log('props signup.js', props);

  const [t] = useTranslation('translation');
  
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [confirmPasswordInput, setConfirmPasswordInput] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [sendVerifyMailMessage, setSendVerifyMailMessage] = useState('');
  const [signupSuccessMessage, setSignupSuccessMessage] = useState('');
  const [parmFromUrl, setParamFromUrl] = useState('');
  const [canAccessPage, setCanAccessPage] = useState(false);
  // const [recaptchaV3Score, setRecaptchaV3Score] = useState('');
  const [recaptchaV2Value, setRecaptchaV2Value] = useState('');

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.searchParams;
    const fromUrl = queryParams.get('fromUrl');

    const isValidSite = isValidFromUrl(fromUrl);

    if (fromUrl && isValidSite) {
      setCanAccessPage(true);
    }

    if (fromUrl && !isValidSite) {
      window.location.replace(
        window.location.origin
      );
    }

    if (!fromUrl || fromUrl === 'null') {
      console.log('no fromUrl');
    } else {
      setParamFromUrl(fromUrl);
    }

  }, []);

  useEffect(() => {
    setIsLoading(props.recaptchaLoading);
  }, [props.recaptchaLoading]);


  // const currentUrl = new URL(window.location.href);
  // const queryParams = currentUrl.searchParams;
  // const fromUrl = queryParams.get('fromUrl');
  // const fromUrl = decodeURIComponent(JSON.parse(`"${queryParams.get('fromUrl')}"`));
  // // const fromUrl = 'http://localhost:8502';


  // const emailInputHandler = (event) => {
  //   // console.log(event.target.value);
  //   setEmailInput(event.target.value);
  // };

  // const passwordInputHandler = (event) => {
  //   // console.log(event.target.value);
  //   setPasswordInput(event.target.value);
  // };


  const emailInputHandler2 = (input, value) => {
    setEmailInput(value);
    setError('');
    // setEmailError('');
    // setPasswordError('');
    // console.log(commentInput);
  }

  const passwordInputHandler2 = (input, value) => {
    setPasswordInput(value);
    setError('');
    // setPasswordError('');
    // setEmailError('');
    // console.log(commentInput);
  }

  const confirmPasswordInputHandler = (input, value) => {
    setConfirmPasswordInput(value);
    setError('');
    // setPasswordError('');
    // setEmailError('');
    // console.log(confirmPasswordInput);
  }

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  }


  const signupHandler = (email, password, confirmPass) => {
    setIsLoading(true);
    setError('');

    if (!validEmail(email)) {
      console.log('invalid email');
      setError(
        // 'Please Enter Valid Email'
        i18n.t('authSignup.text1')
      );
      setIsLoading(false);
      return;
    }

    if (!password || (password && password.length < 8)) {
      console.log('invalid password');
      setError(
        // 'Password should be longer than 8 characters'
        i18n.t('authSignup.text2')
      );
      setIsLoading(false);
      return;
    }

    if (!confirmPass || password !== confirmPass) {
      console.log('passwords should be matched');
      setError(
        // 'Passwords should be matched'
        i18n.t('authSignup.text3')
      );
      setIsLoading(false);
      return;
    }

    // if (
    //   !validEmail(email) || 
    //   !password || (password && password.length < 8) ||
    //   password !== confirmPass
    // ) {
    //   console.log('Inputs are invalid');
    //   setIsLoading(false);
    //   return;
    // } else {
    //   // return;
    // }

    console.log('Inputs are valid');

    apiGetTest(nodeUrl)
      .then(result => {
        console.log(result);
        return firebase.auth().createUserWithEmailAndPassword(email, password)
      })
      // firebase.auth().createUserWithEmailAndPassword(email, password)
      .then((user) => {
        // Signed in 
        console.log(user);

        // return fetch('/api/cas-auth/signup', {
        return fetch(nodeUrl + '/cas-auth/signup', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: password
          })
        })
      })
      .then(res => {
        if (res.status === 422) {
          throw new Error(
            // "Validation failed. Make sure the email address isn't used yet!"
            i18n.t('auth.text11')
          );
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log('Error!');
          // throw new Error('Creating a user failed!');
          // throw new Error('User creation failed!');
          throw new Error(i18n.t('auth.text15'));
        }

        return res.json();
      })
      .then(resData => {
        console.log(resData);

        const currentUrl = new URL(window.location.href);
        const queryParams = currentUrl.searchParams;
        const fromUrl = queryParams.get('fromUrl');

        // const fromUrl = decodeURIComponent(JSON.parse(`"${queryParams.get('fromUrl')}"`));
        // const fromUrl = 'http://localhost:8502';
        console.log(fromUrl);

        var user = firebase.auth().currentUser;
        firebase.auth().useDeviceLanguage();

        var actionCodeSettings = {
          // url: fromUrl + `/?email=${firebase.auth().currentUser.email}`,
          url: fromUrl,
          // iOS: {
          //   bundleId: 'com.example.ios'
          // },
          // android: {
          //   packageName: 'com.example.android',
          //   installApp: true,
          //   minimumVersion: '12'
          // },
          // handleCodeInApp: true,
          // // When multiple custom dynamic link domains are defined, specify which
          // // one to use.
          // dynamicLinkDomain: "example.page.link"
        }
        user.sendEmailVerification(actionCodeSettings)
          .then(() => {
            // Email sent.
            console.log('verification mail was sent');
            // setSendVerifyMailMessage('Verification Mail was sent. Plese check your email and verify. (also chack in spam mail)');
            setSendVerifyMailMessage(i18n.t('auth.text31'));
            setIsLoading(false);

          }).catch((err) => {
            // An error happened.
            console.log(err);

            setIsLoading(false);

            setError(err.message);
          });


        // setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        // var errorCode = err.code;
        // var errorMessage = err.message;

        if (err.code === 'auth/email-already-in-use') {
          // err.message = 'The email address is already in use by another account.';
          err.message = i18n.t('auth.text16');
        }

        setError(err.message);
        setIsLoading(false);
      })

  }


  const signInWithGoogleHandler = () => {
    setIsLoading(true);
    setError('');

    apiGetTest(nodeUrl)
      .then(result => {
        console.log(result);
        return signInWithGoogle();
      })
      .then(resData => {
        console.log(resData);

        const currentUrl = new URL(window.location.href);
        const queryParams = currentUrl.searchParams;
        const fromUrl = queryParams.get('fromUrl');

        if (fromUrl) {
            console.log(fromUrl)
            window.location.replace(
              fromUrl + `/?tokenForCasTGT=${resData.data.token}&casTGT=${localStorage.getItem('casTGT')}&TGTexp=${resData.data.TGTexp.toString()}`
            );
        }

        setIsLoading(false);

      })
      .catch(err => {
        console.log(err);

        setIsLoading(false);
        setError(err.message);
      })
  };


  const signInWithFacebookHandler = () => {
    setIsLoading(true);
    setError('');

    apiGetTest(nodeUrl)
      .then(result => {
        console.log(result);
        return signInWithFacebook();
      })
      .then(resData => {
        console.log(resData);

        const currentUrl = new URL(window.location.href);
        const queryParams = currentUrl.searchParams;
        const fromUrl = queryParams.get('fromUrl');

        if (fromUrl) {
            console.log(fromUrl)
            window.location.replace(
              fromUrl + `/?tokenForCasTGT=${resData.data.token}&casTGT=${localStorage.getItem('casTGT')}&TGTexp=${resData.data.TGTexp.toString()}`
            );
        }

        setIsLoading(false);

      })
      .catch(err => {
        console.log(err);

        setIsLoading(false);
        setError(err.message);
      })
  };


  // const setRecaptchaV3ScoreHandler = (score) => {
  //   setRecaptchaV3Score(score)
  // };

  // const setRecaptchaV2ValueHandler = (value) => {
  //   setRecaptchaV2Value(value);
  // };

  const onChangeV2 = (value) => {
    console.log("Captcha value:", value);

    setRecaptchaV2Value(value);
  }




  const recaptchaLimit = 0.3
  let body;

  if (!parmFromUrl) {
    body = <div></div>
  } 
  if (!canAccessPage) {
    body = <div></div>
  }
  if (isLoading) {
    body = (
      <div className="auth-form__loader">
        <Loader />
      </div>
    );
  }
  
  if (props.recaptchaV3Score && props.recaptchaV3Score <= recaptchaLimit && !recaptchaV2Value) {
    body = <div className="auth-form__recaptchaV2Container">
      <div className="auth-form__recaptchaV2title">Please check before start</div>
      <div id="rec" >
        <ReCAPTCHAV2
          style={{ display: "inline-block" }}
          size="normal"
          sitekey="6LfgpFgaAAAAABaidvxbCOX5JGVFdsTQQI1rOmt5"
          onChange={onChangeV2}
        />
      </div>
    </div>
  }
  // else {
  if (recaptchaV2Value || props.recaptchaV3Score > recaptchaLimit) {
    body = (
      <section className="auth-form">

        <div className='auth-form__logoTitle'>
          <img 
            className='auth-form__logo'
            src={logoImage}
            alt='logo'
          />
          <span>SpaceEight</span>
        </div>

        <div className="auth-form__title">
          {/* Signup Page */}
          {i18n.t('authSignup.text4')}
        </div>

        <Input
          id="email"
          // label="Your E-Mail"
          label={i18n.t('auth.text1')}
          type="email"
          control="input"
          onChange={emailInputHandler2}
          value={emailInput}
          placeholder="enter your E-mail address here..."
          placeholder=""
        // onBlur={this.inputBlurHandler.bind(this, 'email')}
        // value={this.state.loginForm['email'].value}
        // valid={this.state.loginForm['email'].valid}
        // touched={this.state.loginForm['email'].touched}
        />
        <Input
          id="password"
          // label="Password"
          label={i18n.t('auth.text3')}
          type="password"
          type={showPassword ? '' : 'password'}
          control="input"
          onChange={passwordInputHandler2}
          value={passwordInput}
          placeholder="enter password here..."
          placeholder=""
        // onBlur={this.inputBlurHandler.bind(this, 'password')}
        // value={this.state.loginForm['password'].value}
        // valid={this.state.loginForm['password'].valid}
        // touched={this.state.loginForm['password'].touched}
        />

        <Input
          id="confirmpassword"
          // label="Confirm Password"
          label={i18n.t('authSignup.text5')}
          type="password"
          type={showPassword ? '' : 'password'}
          control="input"
          onChange={confirmPasswordInputHandler}
          value={confirmPasswordInput}
          placeholder="confirm password here..."
          placeholder=""
        // onBlur={this.inputBlurHandler.bind(this, 'password')}
        // value={this.state.loginForm['password'].value}
        // valid={this.state.loginForm['password'].valid}
        // touched={this.state.loginForm['password'].touched}
        />

        <div>
          <input className="auth-form__checkbox"
            id="checkbox"
            // label="Password"
            // label={i18n.t('auth.text3')}
            type="checkbox"
            // type={this.state.showPassword ? '' : 'password'}
            // control="input"
            onChange={showPasswordHandler}
          // value={passwordInput}
          />
          <label htmlFor="scales">
            {/* Show Entered Password */}
            {i18n.t('auth.text17')}
          </label>
        </div>

        <div className="auth-form__passwordLength">
          {/* Password should be more than 8 characters. */}
          {i18n.t('authSignup.text6')}
        <br /><br />
          {/* It is recommended that password contains at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&). */}
          {i18n.t('authSignup.text7')}
        </div>

        {/* <div>
        {emailError}
      </div>
      <div>
        {passwordError}
      </div> */}

        <Button design="raised" type="submit"
          onClick={() => { 
            signupHandler(
              emailInput, 
              passwordInput, 
              confirmPasswordInput
            );
          }}
          loading={isLoading}
        // disabled={!this.state.enoughPassword}
        >
          {/* Signup */}
          {i18n.t('auth.text5')}
        </Button>

        <div className="auth-form__emailVerifyMessage">
          {/* {signupSuccessMessage} */}
          {sendVerifyMailMessage}
          {error}

          <div>
            {/* {emailError} */}
          </div>
          <div>
            {/* {passwordError} */}
          </div>

        </div>

        {isLoading ?
          <div className="auth-form__loader">
            <Loader />
          </div>
          : null
        }
        
        {/* {isLoading ? null : <div className="auth-form__otherServiceLoginContainer">
            <div>OR</div>

            <div className="auth-form__otherServiceLoginButton auth-form__googleLogin" onClick={signInWithGoogleHandler}>
              Login with Google account
            </div>
  
            <div className="auth-form__otherServiceLoginButton auth-form__facebookLogin" onClick={signInWithFacebookHandler}>
              Login with Facebook account
            </div>
          </div>
          } */}

      </section>
    )
  }

  return (
    <div>

      {/* <Recaptcha 
        // setRecaptchaV3ScoreHandler={setRecaptchaV3ScoreHandler}
        // setRecaptchaV2ValueHandler={setRecaptchaV2ValueHandler}
      /> */}

      {body}   
    </div>
  );
}

export default Signup;