import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next/hooks';
import { Link } from 'react-router-dom';
import ReCAPTCHAV2 from "react-google-recaptcha";

// import Auth from './Auth';
import Input from '../../Form/Input/Input';
import i18n from '../../../i18n';
import Loader from '../../Loader/Loader';
import Button from '../../Button/Button';
// import ErrorHandler from '../ErrorHandler/ErrorHandler';
import { validEmail, isValidFromUrl } from '../../util/validators';
import { 
  getTokenForPasswordReset, 
  apiGetTest, 
  signInWithGoogle, 
  signInWithFacebook,
  storeUserGeoData,
} from '../../util/user';
import { setCaretPosition } from '../../util/style';

import { casUrl, nodeUrl, userAccountUrl } from '../../../App';
// import UserActions from './UserActions';

import logoImage from '../../../image/s8-logo.png';

import './Auth.css';

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";


import TwoFactorAuth from '../../TwoFactorAuth/TwoFactorAuth';

const Login = props => {

  const [t] = useTranslation('translation');

  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [forgetPassword, setForgetPassword] = useState(false);
  const [passwordResetInput, setPasswordResetInput] = useState('');
  const [passwordResetLoading, setPasswordResetLoading] = useState(false);
  const [sendEmailMessage, setSendEmailMessage] = useState('');
  const [sendEmailError, setSendEmailError] = useState('');
  const [sendVerifyMailMessage, setSendVerifyMailMessage] = useState('');
  const [paramFromUrl, setParamFromUrl] = useState('');
  const [canAccessPage, setCanAccessPage] = useState(false);

  const [recaptchaV2Value, setRecaptchaV2Value] = useState('');

  const [tfaRequired, setTfaRequired] = useState(false);

  const [emailUserExist, setEmailUserExist] = useState(false);
  const [emailCheckError, setEmailCheckError] = useState('');

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.searchParams;
    const fromUrl = queryParams.get('fromUrl');

    const isValidSite = isValidFromUrl(fromUrl);

    if (fromUrl && isValidSite) {
      setCanAccessPage(true);
    }

    if (fromUrl && !isValidSite) {
      window.location.replace(
        window.location.origin
      );
    }

    //// implement site selection
    // if (fromUrl === 'http://localhost:8502') {
    //   setParamFromUrl(fromUrl);
    // }

    if (!fromUrl || fromUrl === 'null') {
      console.log('no fromUrl');
    } else {
      setParamFromUrl(fromUrl);
    }

    setCaretPosition('email', 0);
  }, []);

  useEffect(() => {
    setIsLoading(props.recaptchaLoading);
  }, [props.recaptchaLoading]);

  
  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('set2fa');
    localStorage.removeItem('TGTexp');
  },[]);


  // const currentUrl = new URL(window.location.href);
  // const queryParams = currentUrl.searchParams;
  // const fromUrl = queryParams.get('fromUrl');


  // const emailInputHandler = (event) => {
  //   // console.log(event.target.value);
  //   setEmailInput(event.target.value);
  // };

  // const passwordInputHandler = (event) => {
  //   // console.log(event.target.value);
  //   setPasswordInput(event.target.value);
  // };

  const emailInputHandler2 = (input, value) => {
    setEmailInput(value);
    setError('');

    setEmailUserExist(false);
    setEmailCheckError('');
    // setEmailError('');
    // console.log(commentInput);
  }

  const passwordInputHandler2 = (input, value) => {
    setPasswordInput(value);
    setError('');
    // setPasswordError('');
    // console.log(commentInput);
  }

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  }

  const forgetPasswordHandler = () => {
    setForgetPassword(!forgetPassword);
  };

  const passwordResetInputHandler = (input, value) => {
    setSendEmailMessage('');
    setSendEmailError('');

    setPasswordResetInput(value);

    // validEmail(value);
  }

  const sendPasswordResetMailHandler = () => {
    // // this.setState({ passwordResetLoading: true });
    setPasswordResetLoading(true);
    setSendEmailMessage('');
    setSendEmailError('');

    var auth = firebase.auth();
    auth.useDeviceLanguage();
    console.log(auth.languageCode);

    var emailAddress = passwordResetInput;
    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.searchParams;
    const fromUrl = queryParams.get('fromUrl');
    // const fromUrl = decodeURIComponent(JSON.parse(`"${queryParams.get('fromUrl')}"`));

    apiGetTest(nodeUrl)
      .then(result => {
        console.log(result);
        return getTokenForPasswordReset(nodeUrl, emailAddress, fromUrl)
      })

    // getTokenForPasswordReset(nodeUrl, emailAddress, fromUrl)
      .then(result => {
        console.log(result);

        return auth.sendPasswordResetEmail(emailAddress)
      })
      .then(() => {
        // Email sent.
        console.log('password reset mail was sent');

        // setSendEmailMessage('email for password reset was sent. mail might be in spam mail');
        setSendEmailMessage(i18n.t('authLogin.text4'));
        setPasswordResetLoading(false);

      })
      .catch((err) => {
        // An error happened.
        console.log(err);
        if (err.code === 'auth/user-not-found') {
          // err.message = 'Entered email was not found'
          err.message = i18n.t('auth.text19')
        }

        // err.message = 'Sending email for password reset failed, Please check email address'
        err.message = i18n.t('auth.text27');

        setSendEmailError(err.message);
        setPasswordResetLoading(false);
      });

  }


  const requestLoginTokenHandler = (email, password, firebaseData) => {
    setIsLoading(true);

    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.searchParams;
    const fromUrl = queryParams.get('fromUrl');
    const lsUuid = queryParams.get('lsUuid');
    // const fromUrl = decodeURIComponent(JSON.parse(`"${queryParams.get('fromUrl')}"`));

    // fetch('/api/cas-auth/login-token', {
    fetch(nodeUrl + '/cas-auth/login-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: password,
        fromUrl: fromUrl ? fromUrl : '',
        firebaseData: firebaseData,
        lsUuid: lsUuid ? lsUuid : '',
      })
    })
      .then(res => {
        if (res.status === 422) {
          // throw new Error('Validation failed.');
          throw new Error(
            // "Validation failed. Make sure the email address isn't used yet!"
            i18n.t('auth.text11')
          );
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log('Error!');
          // throw new Error('Could not authenticate you!');
          // throw new Error('User login failed!')
          throw new Error(i18n.t('auth.text12'));
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);

        if (resData.set2fa) {
          localStorage.setItem('token', resData.token);
          localStorage.setItem('email', resData.email);
          localStorage.setItem('TGTexp', resData.TGTexp);
          localStorage.setItem('set2fa', resData.set2fa);
  
          console.log('lsToken stored');
          setTfaRequired(true);
        } 
        else {


          if (fromUrl) {
            console.log(fromUrl)
            window.location.replace(
              fromUrl + `/?tokenForCasTGT=${resData.token}&casTGT=${localStorage.getItem('casTGT')}&TGTexp=${resData.TGTexp.toString()}`
            );
          }


        }




          
        setIsLoading(false);

      })
      .catch(err => {
        console.log(err);

        setError(err.message);
        setIsLoading(false);
      })
  };

  // const createFormForCasTgt = (email, password, additional) => {
  //   var details = {
  //     'username': email,
  //     'password': password,
  //     // 'token': 'true',
  //     'additionalParam1': 'paramvalue',
  //     'attribute': 'attribute'
  //   };

  //   var formBody = [];
  //   for (var property in details) {
  //     var encodedKey = encodeURIComponent(property);
  //     var encodedValue = encodeURIComponent(details[property]);
  //     formBody.push(encodedKey + "=" + encodedValue);
  //   }
  //   formBody = formBody.join("&");
  //   return formBody;
  // }

  // const requestCasTgt = (email, password) => {
  //   setIsLoading(true);

  //   if (!validEmail(email)) {
  //     console.log('invalid email');
  //     setEmailError('Please Enter Valid Email');
  //   }

  //   if (!password || (password && password.length < 8)) {
  //     console.log('invalid password');
  //     setPasswordError('Password should be longer than 8 characters');
  //   }

  //   if (!validEmail(email) || !password || (password && password.length < 8)) {
  //     console.log('Inputs are invalid');
  //     setIsLoading(false);
  //     return;
  //   } else {
  //     console.log('Inputs are valid');
  //     // return;
  //   }

  //   const formBody = createFormForCasTgt(email, password);

  //   // fetch('/cas/v1/tickets', {
  //   fetch(casUrl + '/cas/v1/users', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  //       // 'Accept': 'application/json'
  //     },
  //     body: formBody
  //   })
  //     .then(result => {
  //       console.log(result);
  //       // console.log('result.body', result.body)
  //       // console.log('result.headers', result.headers);
  //       console.log('result.headers.entries()', result.headers.entries());
  //       // for (const entry of result.headers.entries()) {
  //       //   console.log('entry', entry);
  //       // };

  //       console.log('result.status', result.status, 'result.statusText', result.statusText);
  //       // console.log(result.headers.get('location'));

  //       if (result.headers.get('location')) {
  //         const locationArray = result.headers.get('location').split('/');
  //         const tgtTicket = locationArray[locationArray.length - 1];
  //         console.log('TGT ticket: ', tgtTicket);
  //         // localStorage.setItem('casTGT', tgtTicket);

  //         return firebase.auth().signInWithEmailAndPassword(email, password)
  //       } else {
  //         throw new Error('failed to get TGT');
  //       }
  //     })
  //     .then((user) => {
  //       // Signed in 
  //       // ...
  //       console.log(user);
  //       requestLoginTokenHandler(email, password);

  //       setIsLoading(false);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       var errorCode = err.code;
  //       var errorMessage = err.message;

  //       setIsLoading(false);
  //     })
  // };

  // const getCasTgtStatus = (tgtTicket) => {
  //   fetch(`/cas/v1/tickets/${tgtTicket}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  //       // 'Accept': 'application/json'
  //     },
  //   })
  //     .then(result => {
  //       console.log(result);
  //       console.log('result.status', result.status, 'result.statusText', result.statusText);

  //       if (result.status === 404) {
  //         localStorage.removeItem('casTGT');
  //         localStorage.removeItem('tokenForCasTGT');
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     })
  // }

  const loginHandler = (email, password) => {
    setIsLoading(true);
    setError('');

    if (!validEmail(email)) {
      console.log('invalid email');
      // setEmailError('Please Enter Valid Email');
      setIsLoading(false);
      setError(
        // 'Please Enter Valid Email'
        i18n.t('authSignup.text1')
      );
      return;
    }

    if (!password || (password && password.length < 8)) {
      console.log('invalid password');
      // setPasswordError('Password should be longer than 8 characters');
      setIsLoading(false);
      setError(
        // 'Password should be longer than 8 characters'
        i18n.t('authSignup.text2')
      );
      return;
    }

    // if (!validEmail(email) || !password || (password && password.length < 8)) {
    //   console.log('Inputs are invalid');
    //   setIsLoading(false);
    //   return;
    // } else {
    //   console.log('Inputs are valid');
    //   // return;
    // }

    console.log('Inputs are valid');

    let ip;
    let geo;
    let fbUser;

    apiGetTest(nodeUrl)
      .then(result => {
        console.log(result);
        ip = result.data.ip;
        geo = result.data.geo;

        return firebase.auth().signInWithEmailAndPassword(email, password)
      })

      // firebase.auth().signInWithEmailAndPassword(email, password)
      .then((user) => {
        // Signed in 
        // ...
        fbUser = user;
        console.log(user);
        console.log(user.user.emailVerified);

        //
        if (user.user && !user.user.emailVerified) {
          // verificationState = 'not_verified'
          throw new Error('not_email_verify');
        }

        return storeUserGeoData(nodeUrl, email, ip, geo, navigator.userAgentData);

        // requestLoginTokenHandler(email, password, user);
        // setIsLoading(false);
      })
      .then(result => {
        console.log(result);

        requestLoginTokenHandler(email, password, fbUser);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        // var errorCode = err.code;
        // var errorMessage = err.message;

        // console.log(err.message);
        if (err.message === 'not_email_verify') {
          err.message = (
            <div>
              <div>
                {/* Email Verification is required to Login. Please send Email for verification to your Email address. */}
                {i18n.t('auth.text32')}
              </div>
              <br/>
              <Button design="raised" type="submit" 
                onClick={sendVerificationEmail}
              >
                {/* Send Email for verification */}
                {i18n.t('auth.text33')}
              </Button>
              <br/>
              {/* <div>
                {this.state.sendVerifyMailMessage}
              </div> */}
            </div>
          );
        }

        // var errorCode = error.code;
        // var errorMessage = error.message;
        if (err.code === 'auth/wrong-password') {
          // err.message = 'Invalid Password was Entered'
          err.message = i18n.t('auth.text13');
        }
        if (err.code === 'auth/user-not-found') {
          // err.message = 'Entered email address was not found'
          err.message = i18n.t('auth.text14');
        }

        setError(err.message);
        setIsLoading(false);
      })
  };


  const sendVerificationEmail = () => {
    // set error message for display in error modal

    setIsLoading(true);

    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.searchParams;
    const fromUrl = queryParams.get('fromUrl');

    var user = firebase.auth().currentUser;
    firebase.auth().useDeviceLanguage();
    
    var actionCodeSettings = {
      // url: fromUrl + `/?email=${firebase.auth().currentUser.email}`,
      url: fromUrl,
      // iOS: {
      //   bundleId: 'com.example.ios'
      // },
      // android: {
      //   packageName: 'com.example.android',
      //   installApp: true,
      //   minimumVersion: '12'
      // },
      // handleCodeInApp: true,
      // // When multiple custom dynamic link domains are defined, specify which
      // // one to use.
      // dynamicLinkDomain: "example.page.link"
    }

    user.sendEmailVerification(actionCodeSettings)
    .then(() => {
      // Email sent.
      console.log('verification email send');

      //// throw error, just for display message
      throw new Error('Verification_Mail_was_sent');
      
    })
    .catch((err) => {
      // An error happened.
      console.log(err);
      // console.log('verification mail send failed');

      if (err.message === 'Verification_Mail_was_sent') {
        // setSendVerifyMailMessage('Verification Mail was sent. Please check your email and verify. (also check in spam mail)');
        setSendVerifyMailMessage(i18n.t('auth.text31'));
      } 
      else {
        setSendVerifyMailMessage(err.message);
      }

      setIsLoading(false);
    });
  }

  const signInWithGoogleHandler = () => {
    setIsLoading(true);
    setError('');

    apiGetTest(nodeUrl)
      .then(result => {
        console.log(result);
        return signInWithGoogle();
      })
      .then(resData => {
        console.log(resData);

        const currentUrl = new URL(window.location.href);
        const queryParams = currentUrl.searchParams;
        const fromUrl = queryParams.get('fromUrl');

        if (fromUrl) {
            console.log(fromUrl)
            window.location.replace(
              fromUrl + `/?tokenForCasTGT=${resData.data.token}&casTGT=${localStorage.getItem('casTGT')}&TGTexp=${resData.data.TGTexp.toString()}`
            );
        }

        setIsLoading(false);

      })
      .catch(err => {
        console.log(err);

        setIsLoading(false);
        setError(err.message);
      })
  };


  const signInWithFacebookHandler = () => {
    setIsLoading(true);
    setError('');

    apiGetTest(nodeUrl)
      .then(result => {
        console.log(result);
        return signInWithFacebook();
      })
      .then(resData => {
        console.log(resData);

        const currentUrl = new URL(window.location.href);
        const queryParams = currentUrl.searchParams;
        const fromUrl = queryParams.get('fromUrl');

        if (fromUrl) {
            console.log(fromUrl)
            window.location.replace(
              fromUrl + `/?tokenForCasTGT=${resData.data.token}&casTGT=${localStorage.getItem('casTGT')}&TGTexp=${resData.data.TGTexp.toString()}`
            );
        }

        setIsLoading(false);

      })
      .catch(err => {
        console.log(err);

        setIsLoading(false);
        setError(err.message);
      })
  };


  const checkEmailUserHandler = async (email) => {
    try {
      setIsLoading(true);

      const result = await fetch(nodeUrl + '/cas-auth/check-email-user-exist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        })
      });

      if (result.status === 404) {
        setEmailCheckError('Email user not found');
      }

      if (!result.ok) {
        throw new Error('error occured');
      }

      // const resData = await result.json();
      // console.log(resData);

      setEmailUserExist(true);
      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      // throw err;
    }
  };
  // const nodeConnectHandler = () => {
  //   setIsLoading(true);

  //   fetch(nodeUrl + `/get-test`, {
  //   // fetch(`/api2/get-test`, {
  //     method: 'GET',
  //     headers: {
  //       // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  //       'Accept': 'application/json'
  //     },
  //   })
  //     .then(result => {
  //       console.log(result);
  //       console.log('result.status', result.status, 'result.statusText', result.statusText);
  //       return result.json();
  //     })
  //     .then(resultData => {
  //       console.log(resultData);
  //       setIsLoading(false);
  //     }) 
  //     .catch(err => {
  //       console.log(err);
  //       setTimeout(() => {
  //         setIsLoading(false);
  //       }, 2000)
  //     })
  // }

  const onChangeV2 = (value) => {
    console.log("Captcha value:", value);

    setRecaptchaV2Value(value);
  }


  const recaptchaLimit = 0.3
  let body;

  if (!paramFromUrl) {
    body = (
      <div>
        {/* no-from-login */}
        Page is invalid. Access from service sites is required.
      </div>
    );
  } 
  if (!canAccessPage) {
    body = <div></div>
  }
  if (isLoading) {
    body = (
      <div className="auth-form__loader">
        <Loader />
      </div>
    );
  }
  
  if (props.recaptchaV3Score && props.recaptchaV3Score <= recaptchaLimit && !recaptchaV2Value) {
    body = <div className="auth-form__recaptchaV2Container">
      <div className="auth-form__recaptchaV2title">Please check before start</div>
      <div id="rec" >
        <ReCAPTCHAV2
          style={{ display: "inline-block" }}
          size="normal"
          sitekey="6LfgpFgaAAAAABaidvxbCOX5JGVFdsTQQI1rOmt5"
          onChange={onChangeV2}
        />
      </div>
    </div>
  }

  // else {
  if (recaptchaV2Value || props.recaptchaV3Score > recaptchaLimit) {
    body = (
      <section className="auth-form">

        <div className='auth-form__logoTitle'>
          <img 
            className='auth-form__logo'
            src={logoImage}
            alt='logo'
          />
          <span>SpaceEight</span>
        </div>

        <div className="auth-form__title">
          {/* Login Page */}
          {i18n.t('authLogin.text1')}
        </div>

          <Input
            id="email"
            // label="Your E-Mail"
            label={i18n.t('auth.text1')}
            type="email"
            control="input"
            onChange={emailInputHandler2}
            value={emailInput}
            // placeholder="enter your E-mail address for login here..."
            placeholder=""
          // onBlur={this.inputBlurHandler.bind(this, 'email')}
          // value={this.state.loginForm['email'].value}
          // valid={this.state.loginForm['email'].valid}
          // touched={this.state.loginForm['email'].touched}
          />

        {/* {emailUserExist && (
          <div>{emailInput}</div>
        )} */}

        {/* {!emailUserExist && (
          <div>
            <Button design="raised" type="submit"
              disabled={!validEmail(emailInput) || isLoading}
              loading={isLoading}
              onClick={() => {
                checkEmailUserHandler(emailInput);
              }}
            >
              Next
            </Button>
            {isLoading && (
              <Loader />
            )}

            <div>
              <strong>{emailCheckError}</strong>
            </div>
          </div>
        )} */}

          <div>
            <Input
              id="password"
              // label="Password"
              label={i18n.t('auth.text3')}
              // type="password"
              type={showPassword ? '' : 'password'}
              control="input"
              onChange={passwordInputHandler2}
              value={passwordInput}
              // placeholder="enter password here..."
              placeholder=""
            // onBlur={this.inputBlurHandler.bind(this, 'password')}
            // value={this.state.loginForm['password'].value}
            // valid={this.state.loginForm['password'].valid}
            // touched={this.state.loginForm['password'].touched}
            />
    
            <div>
              <input className="auth-form__checkbox"
                id="checkbox"
                // label="Password"
                // label={i18n.t('auth.text3')}
                type="checkbox"
                // type={this.state.showPassword ? '' : 'password'}
                // control="input"
                onChange={showPasswordHandler}
              // value={passwordInput}
    
              // onBlur={this.inputBlurHandler.bind(this, 'password')}
              // value={this.state.loginForm['password'].value}
              // valid={this.state.loginForm['password'].valid}
              // touched={this.state.loginForm['password'].touched}
              />
              <label htmlFor="scales">
                {/* Show Entered Password */}
                {i18n.t('auth.text17')}
              </label>
            </div>
    
            {/* <div>
            {emailError}
          </div>
          <div>
            {passwordError}
          </div> */}
    
            <Button design="raised" type="submit"
              onClick={() => {
                // requestCasTgt(emailInput, passwordInput);
                loginHandler(emailInput, passwordInput);
              }}
              loading={isLoading}
            // disabled={!this.state.enoughPassword}
            >
              {/* Login */}
              {i18n.t('auth.text6')}
            </Button>
    
            {isLoading ?
              <div className="auth-form__loader">
                <Loader />
              </div>
              : null
            }
            <div className="auth-form__emailVerifyMessage">
              {error}
    
              {/* <div>
                {emailError}
              </div>
              <div>
                {passwordError}
              </div> */}
    
            </div>
            
            <div>
              {sendVerifyMailMessage}
            </div>
    
            <div className="auth-form__foregtPassword" onClick={forgetPasswordHandler}>
              {/* Forget Password ? */}
              {i18n.t('auth.text7')} ?
            </div>
            {forgetPassword ?
              <div>
                <Input
                  id="email-for-reset"
                  // label="E-Mail address for password reset"
                  label={i18n.t('auth.text9')}
                  type="email"
                  control="input"
                  onChange={passwordResetInputHandler}
                  // onBlur={this.inputBlurHandler.bind(this, 'email')}
                  value={passwordResetInput}
                  // placeholder="Enter E-mail address to recieve link for reset"
                  placeholder={i18n.t('authLogin.text2')}
                  // placeholder=""
                // valid={ture}
                // touched={false}
                />
    
                <Button design="raised" type="submit"
                  // loading={this.state.passwordResetLoading}
                  disabled={!validEmail(passwordResetInput) || passwordResetLoading}
                  onClick={sendPasswordResetMailHandler}>
                  {/* Send Email */}
                  {i18n.t('auth.text10')}
                </Button>
    
                {passwordResetLoading ?
                  <div className="auth-form__loader">
                    <Loader />
                  </div>
                  : null
                }
    
                <div className="auth-form__emailVerifyMessage">
                  {sendEmailMessage}
                  {sendEmailError}
                </div>
    
              </div>
              : null
            }
          </div>
      

        <div className="auth-form__notHaveAccount">
          Not have account? 
          {' '}
          {/* <a href="/signup">Signup</a> */}
          <Link className='auth-form__notHaveAccountSignup'
            to={`/signup${window.location.search}`}
          >
            Signup
          </Link>
        </div>

        {/* {isLoading ? null : <div className="auth-form__otherServiceLoginContainer">
            <div>OR</div>

            <div className="auth-form__otherServiceLoginButton auth-form__googleLogin" onClick={signInWithGoogleHandler}>
              Login with Google account
            </div>
  
            <div className="auth-form__otherServiceLoginButton auth-form__facebookLogin" onClick={signInWithFacebookHandler}>
              Login with Facebook account
            </div>
          </div>
        } */}

        <div className='auth-form__tfaContainer'>
          <a className='auth-form__tfaLink'
            href={userAccountUrl}
          >
            {t('2FA.text2', 'Use Two Factor Authentication for security')}
          </a>
        </div>
      </section>

    );
  }
  return (
    <div>
      {tfaRequired && (
        <TwoFactorAuth tfaRequired={tfaRequired} />
      )}
      {!tfaRequired && (
        body
      )}
    </div>


  )
}

export default Login;