import React, { Fragment, Suspense } from 'react';
import { useState, useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import i18n from './i18n';
// import logo from './logo.svg';
import Footer from './components/Pages/Auth/Footer';
import Login from './components/Pages/Auth/Login';
import PrivacyPolicy from './components/Pages/Terms/PrivacyPolicy';
import Signup from './components/Pages/Auth/Signup';
import TermsOfUse from './components/Pages/Terms/TermsOfUse';
import UserActions from './components/Pages/Auth/UserActions';
import Recaptcha from './components/Pages/Auth/Recaptcha';

import OrganizationSignup from './components/Pages/OrganizationAuth/OrganizationSignup';
import OrganizationMemberSignup from './components/Pages/OrganizationAuth/OrganizationMemberSignup';

import './App.css';

//// for dev
// export const casUrl = process.env.REACT_APP_DEV_CASURL;
// export const nodeUrl = process.env.REACT_APP_DEV_NODEURL;
// export const userAccountUrl = process.env.REACT_APP_DEV_USERACCOUNTURL;
// export const allowedUrls = [
//   process.env.REACT_APP_DEV_ALLOW_URL_01,
//   process.env.REACT_APP_DEV_ALLOW_URL_02,
//   process.env.REACT_APP_DEV_ALLOW_URL_03,
//   process.env.REACT_APP_DEV_ALLOW_URL_04,
//   process.env.REACT_APP_DEV_ALLOW_URL_05,
//   process.env.REACT_APP_DEV_ALLOW_URL_06,
// ];



//// for deploy
export const casUrl = process.env.REACT_APP_DEPLOY_CASURL;
export const nodeUrl = process.env.REACT_APP_DEPLOY_NODEURL;
export const userAccountUrl = process.env.REACT_APP_USERACCOUNTURL;
export const allowedUrls = [
  process.env.REACT_APP_ALLOW_URL_01,
  process.env.REACT_APP_ALLOW_URL_02,
  process.env.REACT_APP_ALLOW_URL_03,
  process.env.REACT_APP_ALLOW_URL_04,
  process.env.REACT_APP_ALLOW_URL_05,
  process.env.REACT_APP_ALLOW_URL_06,
  process.env.REACT_APP_ALLOW_URL_07,
  process.env.REACT_APP_ALLOW_URL_08,
  process.env.REACT_APP_ALLOW_URL_09,
  process.env.REACT_APP_ALLOW_URL_10,
  process.env.REACT_APP_ALLOW_URL_11,
  process.env.REACT_APP_ALLOW_URL_12,
  process.env.REACT_APP_ALLOW_URL_13,
  process.env.REACT_APP_ALLOW_URL_14,
  process.env.REACT_APP_ALLOW_URL_15,
  process.env.REACT_APP_ALLOW_URL_16,
  process.env.REACT_APP_ALLOW_URL_17,
  process.env.REACT_APP_ALLOW_URL_18,
  process.env.REACT_APP_ALLOW_URL_19,
  process.env.REACT_APP_ALLOW_URL_20,
  process.env.REACT_APP_ALLOW_URL_21,
  process.env.REACT_APP_ALLOW_URL_22,
  process.env.REACT_APP_ALLOW_URL_23,
  process.env.REACT_APP_ALLOW_URL_24,
  process.env.REACT_APP_ALLOW_URL_25,
  process.env.REACT_APP_ALLOW_URL_26,
  process.env.REACT_APP_ALLOW_URL_27,
  process.env.REACT_APP_ALLOW_URL_28,
  process.env.REACT_APP_ALLOW_URL_29,
  process.env.REACT_APP_ALLOW_URL_30,
  process.env.REACT_APP_ALLOW_URL_31,
  process.env.REACT_APP_ALLOW_URL_32,
  process.env.REACT_APP_ALLOW_URL_33,
  process.env.REACT_APP_ALLOW_URL_34,
  process.env.REACT_APP_ALLOW_URL_35,
];


function App() {

  console.log(window.location)
  const [pathName, setPathName] = useState('/');
  const [recaptchaV3Score, setRecaptchaV3Score] = useState('');
  const [recaptchaLoading, setRecapthaLoading] = useState(false);

  useEffect(() => {
    setPathName(window.location.pathname);
  },[])

  useEffect(() => {
    //// set language if user selected language in localStorage
    const lsUserSelectLng = localStorage.getItem('userSelectLng');
    if (lsUserSelectLng) {
      // console.log(lsUserSelectLng);
      i18n.changeLanguage(lsUserSelectLng);
    }
  },[]);

  const setRecaptchaV3ScoreHandler = (score) => {
    setRecaptchaV3Score(score)
  };

  const setRecaptchaLoadingHandler = (value) => {
    setRecapthaLoading(value);
  }


  let routes = (
    <Switch>

      <Route path="/signup">
        <Signup recaptchaV3Score={recaptchaV3Score} recaptchaLoading={recaptchaLoading} />
      </Route>

      <Route path="/useractions">
        <UserActions />
      </Route>

      <Route path="/privacypolicy">
        <PrivacyPolicy />
      </Route>

      <Route path="/termsofuse">
        <TermsOfUse />
      </Route>

      <Route path="/login">
        <Login recaptchaV3Score={recaptchaV3Score} recaptchaLoading={recaptchaLoading} />
      </Route>
      
      <Route path="/organization-signup">
        <OrganizationSignup 
          recaptchaV3Score={recaptchaV3Score} 
          recaptchaLoading={recaptchaLoading} 
        />
      </Route>

      <Route path="/organization-member-signup">
        <OrganizationMemberSignup
          recaptchaV3Score={recaptchaV3Score} 
          recaptchaLoading={recaptchaLoading} 
        />
      </Route>

      <Route path="/">
        <div>not-found</div>
      </Route>

      {/* <Redirect to="/" /> */}

    </Switch>
  );

  return (
    <Fragment>
      <Suspense fallback={null}>
        <BrowserRouter>
          <div className="AppStretch AppContainer">
            {routes}

            {pathName !== '/' && !recaptchaLoading ? <Footer /> : <div>..</div>}
          
            <Recaptcha 
              setRecaptchaV3ScoreHandler={setRecaptchaV3ScoreHandler}
              setRecaptchaLoadingHandler={setRecaptchaLoadingHandler}
            />

          </div>
        </BrowserRouter>
      </Suspense>
    </Fragment>
  );
}

export default App;
