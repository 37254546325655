import { nodeUrl } from '../../App';

import i18n from '../../i18n';

import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";

export const getUserData = (url, token) => {
  return new Promise((resolve, reject) => {

    fetch(url + '/auth/userdata', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Getting user data failed!');
        }
        return res.json();
      })
      .then(resData => {
        // console.log(resData);
        resolve({ message: 'Get User Data.', userData: resData.data });

      })
      .catch(err => {
        console.log(err);
        reject('Get user data failed.')
      });

      return Promise;
  })
};

export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(success => {
      console.log(success);
      
      const userLocation = {
        coords: {
          latitude: success.coords.latitude,
          longitude: success.coords.longitude,
          altitude: success.coords.altitude,
          accuracy: success.coords.accuracy,
          altitudeAccuracy: success.coords.altitudeAccuracy,
          heading: success.coords.heading,
          speed: success.coords.speed,
        },
        timestamp: success.timestamp,
      }

      localStorage.setItem('userLocation', JSON.stringify(userLocation));
      // console.log(JSON.parse(localStorage.getItem('userLocation')));
      
      resolve({ message: 'get user location data success', data: success });
      
    }, error => {
      console.log(error);

      localStorage.setItem('userLocation', null);
      // console.log(JSON.parse(localStorage.getItem('userLocation')));

      resolve({ message: 'get user location data failed', data: null });
    });
  })
}


export const updateUserLocation = (status, location, url, token) => {
  return new Promise((resolve, reject) => {
    
    // let userLocation = {};
    let userLocation;
    if (location) {
      userLocation = {
        coords: {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
          altitude: location.coords.altitude,
          accuracy: location.coords.accuracy,
          altitudeAccuracy: location.coords.altitudeAccuracy,
          heading: location.coords.heading,
          speed: location.coords.speed,
        },
        timestamp: location.timestamp,
      }
    }

    fetch(url + '/auth/status', {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        status: status,
        geolocation: userLocation,
      })
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Can't update location status!");
        }
        return res.json();
      })
      .then(resData => {
        // console.log(resData);
        resolve({ message: 'update location success', data: resData });
      })
      .catch(err => {
        console.log(err);
        reject({ message: 'update location failed', error: err });
      });

  })
}

export const updateUserName = (name, url, token) => {
  return new Promise((resolve, reject) => {
    
    fetch(url + `/auth/name?userLocation=${localStorage.getItem('userLocation')}`, {
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        // geolocation: userLocation,
      })
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Can't update name!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);

        resolve({ message: 'user name update success', data: resData });
      })
      .catch(err => {
        console.log(err);
        reject({ message: 'user name update failed', error: err });
      });

  })
}

export const updateEmailVerified = (emailVerified, fbUserId, url, token) => {
  return new Promise((resolve, reject) => {
    
    fetch(url + `/auth/email-verified?userLocation=${localStorage.getItem('userLocation')}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        emailVerified: emailVerified,
        fbUserId: fbUserId
        // geolocation: userLocation,
      })
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Can't update emailVerified fbUserId!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);

        resolve({ message: 'emailVerified fbUserId update success', data: resData });
      })
      .catch(err => {
        console.log(err);
        resolve({ message: 'emailVerified fbUserId update failed', error: err });
        // reject({ message: 'emailVerified fbUserId update failed', error: err });
      });

  })
}

export const getUsers = (url, token) => {
  return new Promise((resolve, reject) => {

    fetch(url + '/auth/getusers', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Getting users data failed!');
        }
        return res.json();
      })
      .then(resData => {
        // console.log(resData);
        resolve({ message: 'Get Users Data.', usersData: resData.data });

      })
      .catch(err => {
        console.log(err);
        reject({ message: 'Get users data failed.',  error: err })
      });

      return Promise;
  })
};

export const getFollowingUsers = (userId, url, token) => {
  return new Promise((resolve, reject) => {
    
    fetch(url + `/follow/followusers?userId=${userId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },

    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Getting followingUsers failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);

        resolve({ message: 'followingUsers get success', data: resData.data });
      })
      .catch(err => {
        console.log(err);
        reject({ message: 'Get followingUsers data failed', error: err });
      });
  })
};

export const getFollowingUser = (userId, followingUserId, url, token) => {
  return new Promise((resolve, reject) => {
    
    fetch(url + `/follow/followuser?userId=${userId}&followingUserId=${followingUserId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },

    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Getting followingUser failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);

        resolve({ message: 'followingUser get success', data: resData });
      })
      .catch(err => {
        console.log(err);
        reject({ message: 'Get followingUser data failed', error: err });
      });
  })
};

export const addFollowingUserId = (userId, followingUserId, url, token) => {
  return new Promise((resolve, reject) => {
    
    fetch(url + `/follow/followuser`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: userId,
        followingUserId: followingUserId
      })
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("update followingUsers failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);

        resolve({ message: 'followingUsers update success', data: resData });
      })
      .catch(err => {
        console.log(err);
        reject({ message: 'Update followingUsers failed', error: err });
      });
  })
};

export const deleteFollowingUserId = (userId, followingUserId, url, token) => {
  return new Promise((resolve, reject) => {
    
    fetch(url + `/follow/followuser`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: userId,
        followingUserId: followingUserId
      })
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("followingUsers deletion failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);

        resolve({ message: 'followingUsers delete success', data: resData });
      })
      .catch(err => {
        console.log(err);
        reject({ message: 'Delete followingUser failed', error: err });
      });
  })
}


export const getFavoritePosts = (userId, url, token) => {
  return new Promise((resolve, reject) => {
    
    fetch(url + `/follow/favoriteposts?userId=${userId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },

    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Getting favoritePosts failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);

        resolve({ message: 'favoritePosts get success', data: resData.data });
      })
      .catch(err => {
        console.log(err);
        reject({ message: 'Get favoritePosts data failed', error: err });
      });
  })
};

export const getFavoritePost = (postId, userId, url, token) => {
  return new Promise((resolve, reject) => {
    
    fetch(url + `/follow/favoritepost?userId=${userId}&postId=${postId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },

    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Getting favoritePost failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);

        resolve({ message: 'favoritePost get success', data: resData });
      })
      .catch(err => {
        console.log(err);
        reject({ message: 'Get favoritePost data failed', error: err });
      });
  })
};

export const addFavoritePost = (postId, userId, url, token) => {
  return new Promise((resolve, reject) => {
    
    fetch(url + `/follow/favoritepost`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: userId,
        postId: postId
      })
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("update favoritePosts failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);

        resolve({ message: 'favoritePosts update success', data: resData });
      })
      .catch(err => {
        console.log(err);
        reject({ message: 'Update favoritePosts failed', error: err });
      });
  })
};

export const deleteFavoritePost = (postId, userId, url, token) => {
  return new Promise((resolve, reject) => {
    
    fetch(url + `/follow/favoritepost`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: userId,
        postId: postId
      })
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("delete favoritePost failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);

        resolve({ message: 'favoritePost delete success', data: resData });
      })
      .catch(err => {
        console.log(err);
        reject({ message: 'Delete favoritePost failed', error: err });
      });
  })
};

export const getTokenForPasswordReset = (url, email, fromUrl) => {
  return new Promise((resolve, reject) => {

    // fetch(url + `/auth/reset`, {
    fetch(url + `/cas-auth/reset`, {
      method: 'POST',
      headers: {
        // Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        fromUrl: fromUrl
        })
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Getting token for password reset failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
  
        resolve({ message: 'token for password reset get success', data: resData.data });
      })
      .catch(err => {
        console.log(err);
        reject({ 
          message: 'Sending email for password reset failed, Please check email address', error: err 
        });
      });

  })
};

export const getNewPasswordTokenUser = (url, email) => {
  return new Promise((resolve, reject) => {

    fetch(url + `/cas-auth/passwordreset?email=${email}`, {
      method: 'GET',
      headers: {
        // Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Getting user with reset token failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
  
        resolve({ message: 'user with reset token get success', data: resData.data });
      })
      .catch(err => {
        console.log(err);
        reject({ message: 'Get user with reset token failed', error: err });
      });

  })
}

export const resetPassword = (url, email, password, passwordToken, code) => {
  // console.log('in resetpasswordhandler');
  return new Promise((resolve, reject) => {

    fetch(url + `/cas-auth/passwordreset`, {
      method: 'POST',
      headers: {
        // Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        password: password,
        passwordToken: passwordToken
        })
      })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Updating password failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);

            return firebase.auth().confirmPasswordReset(code, password)
              .then(result => {
                // Update successful.
                console.log('password update success');
                resolve({ message: 'password update success', data: resData.data });
              })
              .catch(err => {
                // An error happened.
                console.log(err);
                reject({ message: 'Update password failed', error: err });
              });
        

      })
      .catch(err => {
        console.log(err);
        reject({ message: 'Update password failed', error: err });
      });

  })
};

export const apiGetTest = (url) => {
  return new Promise((resolve, reject) => {

    fetch(url + `/get-test`, {
      method: 'GET',
      headers: {
        // Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Getting api failed!");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
  
        resolve({ message: 'get api success', data: resData.data });
      })
      .catch(err => {
        console.log(err);
        reject({ 
          // message: 'Service is temporarily not available', 
          message: i18n.t('authLogin.text3'),
          error: err 
        });
      });

  })
}


  //// google-login
  // const provider = new firebase.auth.GoogleAuthProvider();
  // provider.addScope('profile');
  // provider.addScope('email');
  
  export const signInWithGoogle = () => {
    return new Promise((resolve, reject) => {

      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
  
      firebase.auth().signInWithPopup(provider)
      .then(function(result) {
        console.log(result);
        // This gives you a Google Access Token.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
  
        //// store in mdb
        return loginFbOtherSeviceTokenHandler(result)
  
       })
       .then(resData => {
         console.log(resData);
         
         resolve({ message: 'get loginFbOtherSevice token success', data: resData.data });

       })
       .catch(err => {
         console.log(err);

         reject(err);
       });
    })

  };


  export const signInWithFacebook = () => {
    return new Promise((resolve, reject) => {

      const facebookProvider = new firebase.auth.FacebookAuthProvider();
      facebookProvider.addScope('user_birthday');
  
      firebase.auth().signInWithPopup(facebookProvider)
        .then(function(result) {
          console.log(result);
          // This gives you a Facebook Access Token.
          var token = result.credential.accessToken;
          // The signed-in user info.
          var user = result.user;

          //// store in mdb
          return loginFbOtherSeviceTokenHandler(result)
        })
        .then(resData => {
          console.log(resData);
          
          resolve({ message: 'get loginFbOtherSevice token success', data: resData.data });
        }).catch(err => {
          console.log(err);

          reject(err);
        });
    })
  }


  const loginFbOtherSeviceTokenHandler = (firebaseData) => {
    return new Promise((resolve, reject) => {

      fetch(nodeUrl + '/cas-auth/login-fb-other-service-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // email: '',
          // password: '',
          // fromUrl: fromUrl ? fromUrl : '',
          firebaseData,
        })
      })
        .then(res => {
          if (res.status === 422) {
            // throw new Error('Validation failed.');
            throw new Error(
              "Validation failed. Make sure the email address isn't used yet!"
              // i18n.t('auth.text11')
            );
          }
          if (res.status !== 200 && res.status !== 201) {
            console.log('Error!');
            // throw new Error('Could not authenticate you!');
            throw new Error('User login failed!')
            // throw new Error(i18n.t('auth.text12'));
          }
          return res.json();
        })
        .then(resData => {
          console.log(resData);
          
          resolve({ message: 'get login token success', data: resData });
                     
        })
        .catch(err => {
          console.log(err);
          
          reject({ message: 'get login token failed', error: err });
        })
    })
  };


  export const storeUserGeoData = (url, email, ip, geo, userAgentData) => {
    return new Promise((resolve, reject) => {
      fetch(url + `/cas-auth/store-user-geodata`, {
        method: 'POST',
        headers: {
          // Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          ip: ip,
          geo: geo,
          userAgentData: userAgentData,
        }),
      })
        .then(res => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error("Storing user geo data failed");
          }
          return res.json();
        })
        .then(resData => {
          console.log(resData);
    
          resolve({ message: 'Store geo data success', data: resData.data });
        })
        .catch(err => {
          console.log(err);
          reject({ 
            message: 'Store user geo data failed', 
            // message: i18n.t('authLogin.text3'),
            error: err 
          });
        });
  
    })
  }