import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next/hooks';
import i18n from '../../../i18n';

import LanguageSelect from './LanguageSelect';

import './Footer.css';

const Footer = props => {

  const [t] = useTranslation('translation');

  return (
    <div>
      <div className="">
        <LanguageSelect />
      </div>
      <div className="footer">
        <div className="footer__items" onClick={props.onChoose}>
          <Link to="/privacypolicy" className="footer__text">
            {/* Privacy Policy */}
            {i18n.t('privacyPolicy.text1')}
          </Link>
        </div>
        <div className="footer__items" onClick={props.onChoose}>
          <Link to="/termsofuse" className="footer__text">
            {/* Terms of Use */}
            {i18n.t('termsOfUse.text1')}
          </Link>
        </div>
      </div>

      <div className="footer__items" >SpaceEight</div>
  
    </div>
  );
};

export default Footer;
