import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withI18n } from "react-i18next";
import { useTranslation } from 'react-i18next/hooks';

import i18n from '../../../i18n';
import Input from '../../../components/Form/Input/Input';
import Button from '../../../components/Button/Button';
import Loader from '../../../components/Loader/Loader';
import { getNewPasswordTokenUser, resetPassword } from '../../util/user'
import { nodeUrl } from '../../../App';

import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";


const currentUrl = new URL(window.location.href);
const queryParams = currentUrl.searchParams;

let queryEmail;
if (window.location.search) {
  // queryEmail = window.location.search.split('&').pop();
  queryEmail = queryParams.get('mail')
}
// const actionMode = window.location.search.split('&')[0];
const actionMode = queryParams.get('mode');
console.log(actionMode);

const UserActions = props => {

  const [t] = useTranslation('translation');

  // const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  // const [emailError, setEmailError] = useState('');
  // const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [emailVerifyMessage, setEmailVerifyMessage] = useState('');
  const [isUserWithResetToken, setIsUserWithResetToken] = useState(false);
  const [passwordToken, setPasswordToken] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(false);

  useEffect(() => {
    // this.setState({ isLoading: true });
    setIsLoading(true);
    console.log(window.location, queryEmail);

    // getNewPasswordTokenUser(nodeUrl, queryEmail.split('=')[1])
    getNewPasswordTokenUser(nodeUrl, queryEmail)
      .then(result => {
        console.log(result);
        setIsUserWithResetToken(true);
        setPasswordToken(result.data.resetToken);
        setIsLoading(false);

      })
      .catch(err => {
        console.log(err);
        // this.setState({ isLoading: false });

        setIsLoading(false);
      });

    if (actionMode === 'verifyEmail') {
      emailVerificationHandler();
    }
  }, []);


  const passwordInputHandler2 = (input, value) => {
    setPasswordInput(value);
    // setPasswordError('');
    // console.log(commentInput);

    if (!value || value.length < 8) {
      setIsValidPassword(false);
    } else {
      setIsValidPassword(true);
    }

  }

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  }

  const passwordResetHandler = () => {
    // this.setState({ isLoading: true });
    setIsLoading(true);

    // let queryOobCode;
    // if (window.location.search) {
    //   queryOobCode = window.location.search.split('&')[1].split('=')[1];
    // }
    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.searchParams;
    const queryOobCode = queryParams.get('oobCode');


    resetPassword(
      nodeUrl,
      // queryEmail.split('=')[1],
      queryEmail,
      passwordInput,
      passwordToken,
      queryOobCode
    )
      .then(result => {
        console.log(result);

        setPasswordInput('');
        setIsLoading(false);

        // const fromUrl = result.data.fromUrl;
        const fromUrl = encodeURIComponent(result.data.fromUrl);
        const fromUrlLink = `/login?fromUrl=${fromUrl}`;

        setResetMessage(
          <div>
            <span>
              {/* Password reset sucess. */}
              {i18n.t('auth.text21')}
            </span>
            <div>
              <Link className="" to={fromUrlLink}>
                {/* Go to Login Page */}
                {i18n.t('authSignup.text9')}
              </Link>
            </div> 
          </div>
        ); 

      })
      .catch(err => {
        console.log(err);

        setIsLoading(false);
        setResetMessage('Password reset failed, Please go to Login page and send email for password reset again');
        setResetMessage(i18n.t('auth.text22'));

        setPasswordInput('');
      });

  }

  const emailVerificationHandler = () => {
    // this.setState({ isLoading: true });
    setIsLoading(true);

    // const queryOobCode = window.location.search.split('&')[1].split('=')[1];
    
    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.searchParams;
    const queryOobCode = queryParams.get('oobCode');
    console.log(queryOobCode);

    firebase.auth().applyActionCode(queryOobCode)
      .then((resp) => {
        console.log(resp);
 
        // setEmailVerifyMessage('Email verification success, please go to login page shown above')
        // setEmailVerifyMessage(i18n.t('auth.text29'));
        setIsLoading(false);

        const redirectUrl = queryParams.get('continueUrl');

        // const redirectUrl = encodeURIComponent(queryParams.get('continueUrl'));
        // const loginPageLink = `/login?fromUrl=${redirectUrl}`
        
        setEmailVerifyMessage(
          <span>
            {/* Email verification success. */}
            {i18n.t('authSignup.text8')}
            
            <div>
              <a href={redirectUrl}>HomePage</a>
            </div>
              {/* <div>
                <Link className="" to={loginPageLink} >
                  Go to Login Page
                  {i18n.t('authSignup.text9')}
                </Link>
              </div> */}
          </span>
        );
        // Email address has been verified.

        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      }).catch((err) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        console.log(err);

        setEmailVerifyMessage('Email verification failed. This page is expired, or verification is already finished.');
        setEmailVerifyMessage(i18n.t('auth.text30'));

        setIsLoading(false);
        // 'he action code is invalid. This can happen if the code is malformed, expired, or has already been used.'
      });
  }


  let body;
  if (isLoading) {
    body = (
      <div className="auth-form__loader">
        <Loader />
      </div>
    );
  }

  else {

    if (!isUserWithResetToken) {
      body = (
        <div>
          {/* Page is invalid or Password is already changed */}
          {i18n.t('auth.text26')}
        </div>
      );
    }

    if (isUserWithResetToken) {
      body = (
        <div>

          <div>
            {/* Password reset page */}
            {i18n.t('auth.text23')}
          </div>

          <section className="auth-form">

            <Input
              id="password"
              // label="New Password"
              label={i18n.t('auth.text24')}
              placeholder="enter new password here..."
              // type="password"
              type={showPassword ? '' : 'password'}
              control="input"
              // onChange={this.inputChangeHandler}
              onChange={passwordInputHandler2}
              // onBlur={this.inputBlurHandler.bind(this, 'password')}
              // value={this.state.signupForm['password'].value}
              value={passwordInput}
            // valid={this.state.signupForm['password'].valid}
            // touched={this.state.signupForm['password'].touched}
            />

            <div>
              <input className="auth-form__checkbox"
                id="checkbox"
                type="checkbox"
                onChange={showPasswordHandler}
              />
              <label htmlFor="checkbox">
                {/* Show Entered Password */}
                {i18n.t('auth.text17')}
              </label>
            </div>

            <div className="auth-form__passwordLength">
              {/* Password should be more than 8 characters. */}
              {i18n.t('authSignup.text6')}
              <br/>
              <br/>
              {/* It is recommended that password contains at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&). */}
              {i18n.t('authSignup.text7')}
            </div>


            <Button design="raised" type="submit"
              loading={isLoading}
              disabled={!isValidPassword}
              // disabled={!this.state.enoughPassword || !this.state.signupForm['password'].value}
              onClick={passwordResetHandler}
            >
              {/* Reset Password */}
              {i18n.t('auth.text25')}
            </Button>

            <div className="auth-form__passwordResetMessage">
              {/* {this.state.resetMessage} */}
              {resetMessage}
            </div>

          </section>

        </div>
      );
    }

    if (actionMode === 'verifyEmail') {
      body = (<div>
        <div>
          {/* Email verification page */}
          {i18n.t('auth.text28')}
        </div>
        <div className="auth-form__emailVerifyMessage">
          {emailVerifyMessage}
        </div>

        {/* <div>
          <button onClick={() => {this.emailVerificationHandler()}}>veriyhandler-test</button>
        </div> */}
      </div>
      );
    }

    // if (!canApiGet) {
    //   body = (<div>not-api-available</div>);
    // }

  }

  return (
    <div>
      {body}
    </div>
  );
}

export default withI18n()(UserActions);

